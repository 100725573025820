import './css/gethelp.styles.css';
import getHelpIllus from './resources/undraw_Air_support_re_nybl (1).png';
export default function GetHelp() {
    return (
        <div className='get-help-page'>
            <div className='get-help-content'>
                <div className='get-help-first-half'>
                    <div className='firsthalf-content'>
                    <h1 className='get-help-title'>Looking For Some Help?</h1>  
                    <h1 className='get-help-title'>We Got You!</h1>
                    <div className="help-intro">
                        <div className='get-help-intro-text'>
                            <p>Do you need help leaving Scientology? Are you an ex-Scientologist who has been slogging it out after leaving but needs the kind of helping hand and support you never got growing up? We are here for you!</p>
                            <p>Please know that our goal is to help EVERYONE. If there isn't enough to go around, we will prioritize someone who is homeless over someone who has a little more support in their lives. However, we offer many forms of support that go beyond funds,
                                and we strongly encourage people to reapply.</p>
                        </div>
                        <div className="help-illus-container">
                            <img className="help-illus" src={getHelpIllus} alt="Illustration of a man descending with a parachute, symbolizing receiving help or support" />
                        </div>
                    </div>
                    <p>Absolutely everything in the application below is kept confidential and will never be shared. We understand how hard it can be to ask for help, so please forgive the many questions. Due to Scientology’s fair game tactics, we have to be a little cautious.</p>
                    </div>
                </div>
            </div>
            <div className='get-help-second-half'>
                <form className='helpform' action="https://data.endpoint.space/clua9h8g9001408l8j73ke8wo" method="POST" >
                    <h1>Your Information</h1>
                    <div className='helpform-row'>
                        <label htmlFor="firstName">First:</label>
                        <input className="input-gethelp" type="text" id="first" name="first" required />

                        <label htmlFor="lastName">Last:</label>
                        <input className="input-gethelp" type="text" id="last" name="last" required/>
                    </div>

                    <div className='helpform-row'>
                        <label htmlFor="streetAddress">Address:</label>
                        <input className="input-gethelp" type="text" id="Address" name="Address" className='street-address' />
                    </div>
                    <div className='helpform-row'>

                        <label htmlFor="city">City:</label>
                        <input className="input-gethelp" type="text" id="city" name="city" />

                        <label htmlFor="state">State:</label>
                        <input className="input-gethelp" type="text" id="state" name="state" maxLength="2" />

                        <label htmlFor="zipCode">Zip:</label>
                        <input className="input-gethelp" type="text" id="zip" name="zipe" maxLength="5" />
                    </div>
                    <div className='helpform-row'>

                        <label htmlFor="phone">Phone:</label>
                        <input className="input-gethelp" type="tel" id="phone" name="phone" pattern="^(\([0-9]{3}\)|[0-9]{3})[ -]?[0-9]{3}[ -]?[0-9]{4}$" />


                        <label htmlFor="emailAddress">Email:</label>
                        <input className="input-gethelp" type="email" id="email" name="email" />
                    </div>
                    <br></br>
                    <label htmlFor="employed">Are You Currently Employed?</label>
                    <div className='helpform-row' id="checkbox">

                        <input type="checkbox" id="employed" name="employed" className='checkbox input-gethelp' />
                        <label htmlFor="employed">Yes</label>
                        <input type="checkbox" id="unemployed" name="unemployed" className='checkbox input-gethelp'/>
                        <label htmlFor="unemployed">No</label>
                    </div>
                    <div className='helpform-row'>

                        <label htmlFor="employerName">Employer:</label>
                        <input type="text" id="employer" name="employer" className='emp-name input-gethelp' />
                    </div>
                    <div className='helpform-row'>

                        <label htmlFor="phone2">Phone:</label>
                        <input className="input-gethelp" type="tel" id="phone2" name="phone2" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" />

                        <label htmlFor="emailAddress2">Email:</label>
                        <input className="input-gethelp" type="email" id="emailAddress2" name="emailAddress2"/>
                    </div>
                    <br></br>
                    <label htmlFor="situationDescription">Please describe your situation including whether you are leaving or when you did leave, were you Sea Org, staff or public, are you declared and your current situation for which you are requesting aid:</label>
                    <textarea className="gethelp-ta" id="situationDescription" name="situationDescription" rows="15" cols="150" required ></textarea>

                    <label htmlFor="aidDescription">Please describe the type of aid you are looking for whether it is financial or another support program and what you intend to use any financial support for specifically:</label>
                    <textarea className="gethelp-ta" id="aidDescription" name="aidDescription" rows="15" cols="150" required></textarea>

                    <label htmlFor="vouchDescription">Is there someone who can vouch for your current situation? If so, please explain and provide contact info:</label>
                    <textarea className="gethelp-ta" id="vouchDescription" name="vouchDescription" rows="15" cols="150" required></textarea>

                    <label htmlFor="additionalInfo">Is there anything else you would like us to know?</label>
                    <textarea className="gethelp-ta" id="additionalInfo" name="additionalInfo" rows="15" cols="150" ></textarea>

                    <button type="submit" className='apply'>Apply</button>

                </form>
            </div>
        </div>
    );
};